import '@babel/polyfill';
import axios from 'axios';
import objectAssign from 'object-assign';

window.addEventListener("load", (event) => {
  if (window && window.Laravel && window.Laravel.csrfToken) {
    axios.defaults.headers.common = {
      'X-CSRF-TOKEN': window.Laravel.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + window.Laravel.apiToken,
      'X-Referrer': JSON.stringify(window.Laravel.referrer)
    }
  }
});

Object.assign = objectAssign;