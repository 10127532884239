<template>
  <div>
    <span v-if="type == 'step-3'" class="label">
      {{ title }}
      <i v-if="optional">({{ htrLanguage.quiz.optional || 'optional' }})</i>
    </span>
    <div v-else class="title-pannel">
      <label class="text-big required">
        <strong>{{ title }}</strong>
      </label>
    </div>
    <div class="star-rating-box">
      <div class="half-star-rating">
        <input :id="_uid + 'rating10'" v-model="rating" type="radio" :name="inputName" value="5">
        <label :for="_uid + 'rating10'" :title="`5 ${htrLanguage.writeReview.stars || 'stars'}`" />
        <input :id="_uid + 'rating9'" v-model="rating" type="radio" :name="inputName" value="4.5" v-if="!notAllowHalfStars">
        <label class="half" :for="_uid + 'rating9'" :title="`4 1/2 ${htrLanguage.writeReview.stars || 'stars'}`" v-if="!notAllowHalfStars" />
        <input :id="_uid + 'rating8'" v-model="rating" type="radio" :name="inputName" value="4">
        <label :for="_uid + 'rating8'" :title="`4 ${htrLanguage.writeReview.stars || 'stars'}`" />
        <input :id="_uid + 'rating7'" v-model="rating" type="radio" :name="inputName" value="3.5" v-if="!notAllowHalfStars">
        <label class="half" :for="_uid + 'rating7'" :title="`3 1/2 ${htrLanguage.writeReview.stars || 'stars'}`" v-if="!notAllowHalfStars" />
        <input :id="_uid + 'rating6'" v-model="rating" type="radio" :name="inputName" value="3">
        <label :for="_uid + 'rating6'" :title="`3 ${htrLanguage.writeReview.stars || 'stars'}`" />
        <input :id="_uid + 'rating5'" v-model="rating" type="radio" :name="inputName" value="2.5" v-if="!notAllowHalfStars">
        <label class="half" :for="_uid + 'rating5'" :title="`2 1/2 ${htrLanguage.writeReview.stars || 'stars'}`" v-if="!notAllowHalfStars" />
        <input :id="_uid + 'rating4'" v-model="rating" type="radio" :name="inputName" value="2">
        <label :for="_uid + 'rating4'" :title="`2 ${htrLanguage.writeReview.stars || 'stars'}`" />
        <input :id="_uid + 'rating3'" v-model="rating" type="radio" :name="inputName" value="1.5" v-if="!notAllowHalfStars">
        <label class="half" :for="_uid + 'rating3'" :title="`1 1/2 ${htrLanguage.writeReview.stars || 'stars'}`" v-if="!notAllowHalfStars" />
        <input :id="_uid + 'rating2'" v-model="rating" type="radio" :name="inputName" value="1">
        <label :for="_uid + 'rating2'" :title="`1 ${htrLanguage.writeReview.star || 'star'}`" />
        <input :id="_uid + 'rating1'" v-model="rating" type="radio" :name="inputName" value=".5" v-if="!notAllowHalfStars">
        <label class="half" :for="_uid + 'rating1'" :title="`1/2 ${htrLanguage.writeReview.star || 'star'}`" v-if="!notAllowHalfStars" />
      </div>
      <span class="guide-box">{{ label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'inputName', 'type', 'optional', 'value', 'notAllowHalfStars'],
  data () {
    return {
      rating: null,
      hoveredRating: null,
      isHovering: false
    }
  },
  computed: {
    htrLanguage() {
      if (window && window.i18n) {
        const { writeReview, quiz } = window.i18n
        if (writeReview && quiz) {
          return { writeReview, quiz }
        }
      }
      return { writeReview: {}, quiz: {} }
    },
    label () {
      let rating = this.hoveredRating || this.rating
      let defaultLabel = this.htrLanguage.writeReview.click_to_rate || 'click_to_rate'
      switch (true) {
        case (rating === null) :
          return defaultLabel
        case (rating > 4) :
          return this.htrLanguage.writeReview.excellent || 'excellent'
        case (rating > 3) :
          return this.htrLanguage.writeReview.very_good || 'very_good'
        case (rating > 2) :
          return this.htrLanguage.writeReview.average || 'average'
        case (rating > 1) :
          return this.htrLanguage.writeReview.poor || 'poor'
        case (rating <= 1) :
          return this.htrLanguage.writeReview.terrible || 'terrible'
        default :
          return defaultLabel
      }
    }
  },
  watch: {
    rating () {
      this.$emit('input', this.rating)
      this.$emit('rated', { rating: this.rating, name: this.inputName })
    }
  },
  mounted () {
    this.rating = this.value

    $(this.$el).find('.half-star-rating label').map((i, el) => {
      let element = $(el)
      let elementId = '#' + element.attr('for')
      element
        .on('mouseenter', () => (this.hoveredRating = $(elementId).val()))
        .on('mouseout', () => (this.hoveredRating = null))
    })
  }
}
</script>
